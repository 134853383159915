import React, { useState, useEffect } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  LoadingAnimation,
} from "../../../StyleComponents/styles"
import { LeafletCard, ColumnDiv2 } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "IP Address",
    link: "/ip-address/",
  },
]
const seeAlsoArray = [
  "/find-location-of-ip-address",
  "/where-am-i-location-latitude-longitude",
  "/latitude-and-longitude-to-dns-converter",
]

function IPAddress(props) {
  let [loading, setLoading] = useState(false)
  let [resultError, setresultError] = useState("")
  let [ipAddress, setipAddress] = useState("")
  let [leaflet, setLeaflet] = useState(null)
  let [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    fetch("https://api.ip.sb/geoip")
      .then(function (resp) {
        return resp.json()
      })
      .then(function (data) {
        setresultError("")
        setipAddress(data.ip)
        setData(data)
        import("../../../components/MapLeaflet/MapLeaflet")
          .then(module => {
            const MapLeaflet = module.default
            setLeaflet(
              <MapLeaflet
                latitude={data.latitude}
                longitude={data.longitude}
                ip={data.ip}
              />
            )
          })
          .catch(error => console.log(error))
      })
      .catch(function (error) {
        setresultError("Error, Please try again.")
        console.log(error)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Layout location={props.location}>
      <SEO
        title={`${ipAddress} IP Address - what is my IP Address ?`}
        description={`IP Address, what is my IP Address? find your IP Address online. Determine your city, state/region, postal/zip code, Latitude Longitude, Timezone, Neighbours, Timestamp, Currency Code.`}
        keywords={[
          "what is my ip,my ip,my ip address, ip address,ip lookup, ip location, ip address lookup,ip info,ip geolocation, my ip and location,ip,my address,get ip,ip information, info from ip,get Currency from ip,Timezone from ip.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>What is my IP Address ?</Title>
        <SubTitle>
          Your IP Address : <strong>{ipAddress}</strong>
        </SubTitle>
        <br />
        <p>{resultError}</p>
        {leaflet ? <LeafletCard>{leaflet}</LeafletCard> : <LoadingAnimation />}
        <br />
        {data.latitude || loading ? (
          <>
            <p>
              IP Address : <strong>{ipAddress}</strong>
            </p>
            <ColumnDiv2>
              {Object.keys(data).map((e, index) => {
                return <p key={index}>{`${e}: ${data[e]}`}</p>
              })}
              <br />
            </ColumnDiv2>
          </>
        ) : (
          <LoadingAnimation />
        )}
        <h3>IP Address</h3>
        <p>
          Internet Protocol (IP address) exists a numerical identifier attached
          to each device connected to an internet network.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default IPAddress
